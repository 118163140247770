import React from "react"
import Header from "../components/header"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Style from "../styles/index.module.css"

export default () => (
  <div>
    <Helmet>
      <title>Maximo Macchi</title>
    </Helmet>
    <Header />
    <div className={Style.contentContainer}>
      <h1 className={Style.headerText}>Maximo Macchi</h1>
      <div className={Style.bodyText}>I build things for the internet</div>
      <div className={Style.aboutMeButtonContainer}>
        <Link to="/about" className={Style.aboutMeButton}>
          Learn more about me
        </Link>
      </div>
    </div>
  </div>
)
